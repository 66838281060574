<template>
    <section class="slothqueue">
        <h1 class="text-white">SlothQueue</h1>
    </section>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BImg,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BFormGroup,
    BFormCheckbox,
    BLink
  } from 'bootstrap-vue'
  
  
  import { mapState, mapGetters } from "vuex";
  import axiosCustom from '@axios';
  import Vue from "vue";
  
  export default {
    name:"SlothQueue",
    components: {
      BRow,
      BCol,
      BButton,
      BCardText,
      BListGroup,
      BListGroupItem,
      BCard,
      BBadge,
      BImg,
      BFormGroup,
      BFormCheckbox,
      BLink,
    },
    data() {
      return {
      }
    },
    mounted(){
    },
    methods: {
     
    },
    computed: {
      ...mapState([""]),
    },
    watch:{
  
    }
  }
  </script>
  
  <style lang="scss">
    .slothqueue{
      margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
     
    }
  
  </style>
  